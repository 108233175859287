import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';

import Container from 'common/src/components/UI/Container';
import { Auth } from 'aws-amplify';
import BlockWrapper, { Button, TextContainer } from './signup.style';
import { Icon } from 'react-icons-kit';
import { google } from 'react-icons-kit/fa/google';
import { facebook } from 'react-icons-kit/fa/facebook';
import { amazon } from 'react-icons-kit/fa/amazon';
import { apple } from 'react-icons-kit/fa/apple';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import {
  setUser,
  checkUserCognito,
  setPassword,
  isLoggedIn,
} from '../../../utils/auth';
import Row from '../../../components/Row';
import FormField from '../../../components/FormField';
import {
  setStripeCoupon,
  getStripeCoupon,
  setStripePrice,
  getStripePrice,
} from '../../../utils/stripe';

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      charityJson {
        signup {
          title
          subtitle
          slogan
        }
      }
    }
  `);
  
  const [state, setState] = useState({});
  const [displayEmailSignup, setDisplayEmailSignup] = useState(false);

  const data = Data.charityJson.signup;

  const signUpSocial = async provider => {
    // const { username, password, email, phone_number } = this.state

    try {
      await Auth.federatedSignIn({ provider: provider });
      setState({ stage: 1 });
      const user = await Auth.currentAuthenticatedUser();

      const userInfo = {
        userAttributes: user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    } catch (err) {
      setState({ error: err });
      console.log('error signing up...', err);
    }
  };

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <BlockWrapper>
        <Container>
          <Box {...secTitleWrapper}>
            <header>
              <Heading as="h5" {...secText} content={data.slogan} />
              <Heading {...secHeading} content={data.title} />
              <Heading as="h3" {...secHeading} content={data.subtitle} />
            </header>
          </Box>
          <Box>
            <Row>
              <Button
                onClick={() => {
                  signUpSocial(CognitoHostedUIIdentityProvider.Google);
                }}
                className="btnSign btnGoogle"
              >
                <Icon icon={google} />
                <span>Sign up with Google</span>
              </Button>
            </Row>
            <Row>
              <Button
                onClick={() => {
                  signUpSocial(CognitoHostedUIIdentityProvider.Facebook);
                }}
                className="btnSign btnFacebook"
              >
                <Icon icon={facebook} />
                <span>Sign up with Facebook</span>
              </Button>
            </Row>
            <Row>
              <Button
                onClick={() => {
                  signUpSocial(CognitoHostedUIIdentityProvider.Amazon);
                }}
                className="btnSign btnAmazon"
              >
                <Icon icon={amazon} />
                <span>Sign up with Amazon</span>
              </Button>
            </Row>
            <Row>
              <Button
                onClick={() => {
                  signUpSocial(CognitoHostedUIIdentityProvider.Apple);
                }}
                className="btnSign btnApple"
              >
                <Icon icon={apple} />
                <span>Sign up with Apple</span>
              </Button>
            </Row>
          
            {process.env.GATSBY_LOCALHOST===true && (
              <Row>
                <Button
                  onClick={() => {
                    Auth.signOut()
                      .then(data => console.log(data))
                      .catch(err => console.log(err));
                  }}
                  className="btnGooglePlus"
                >
                  <span>Signout</span>
                </Button>
                <Button
                  onClick={() => {
                    checkUserCognito();
                  }}
                  className=""
                >
                  <span>Check user</span>
                </Button>
              </Row>
            )}
            <Heading
              as="h4"
              className="descriptionStyle"
              content={data.description}
            />
          </Box>
          <TextContainer className="stickBottom">
            <h6 className="extra-small">
              <span>
                By subscribing to remotementors.io you agree to the{' '}
              </span>
              <a href="/tos" target="_blank">
                terms and conditions
              </a>
              .
            </h6>
          </TextContainer>          
        </Container>
      </BlockWrapper>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['2rem'],
    pb: '20px',
  },
  secTitleWrapper: {
    mb: ['30px', '40px'],
  },
  secText: {
    as: 'h5',
    display: 'block',
    textAlign: 'center',
    letterSpacing: '0.15em',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    width: '500px',
    maxWidth: '100%',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: '#0f2137',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',

    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // bg: '#fff',
    // color: '#2aa275',
    // colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '15px',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // colors: 'primaryWithBg',
    minWidth: ['160px', '190px'],
    maxWidth: '100%',
    height: '48px',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    mb: '0',
  },
};

export default PricingSection;
